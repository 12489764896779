<template>
	<v-col :lg="member.colLgSize" class="text-center mx-3">
		<v-avatar :size="$vuetify.breakpoint.mdAndUp ? 250 : 160">
			<v-img :src="require('@/assets/images/team/' + member.img)" />
		</v-avatar>
		<p class="text-h5 mt-2">
			{{ member.name }}
		</p>
		<p class="text-muted">
			<span>{{ member.title }}</span>
			<span v-if="member.subtitle">{{ member.subtitle }}</span>
		</p>

		<div class="d-flex justify-center">
			<v-btn v-if="member.webURL" icon :href="member.webURL" target="_blank">
				<v-icon>mdi-earth</v-icon>
			</v-btn>
			<v-btn v-if="member.githubURL" icon :href="member.githubURL" target="_blank">
				<v-icon>mdi-github</v-icon>
			</v-btn>
			<v-btn v-if="member.instagramURL" icon :href="member.instagramURL" target="_blank">
				<v-icon>mdi-instagram</v-icon>
			</v-btn>
			<v-btn v-if="member.linkedinURL" icon :href="member.linkedinURL" target="_blank">
				<v-icon>mdi-linkedin</v-icon>
			</v-btn>
		</div>
	</v-col>
</template>

<script>
export default {
	name: 'TeamMember',
	props: {
		member: {
			type: Object,
			required: true
		}
	}
}
</script>
